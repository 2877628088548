import { EditorReadyFn } from '@wix/yoshi-flow-editor';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowApi,
) => {
  const isEditorX = flowApi?.environment?.isEditorX;
  if (options.firstInstall && isEditorX) {
    editorSDK.addEventListener('widgetAdded', async (event) => {
      const { detail } = event;
      const { componentRef } = detail;
      const responsiveLayout = await editorSDK.document.responsiveLayout.get(
        'token',
        { componentRef },
      );
      await editorSDK.responsiveLayout.update('token', {
        componentRef,
        responsiveLayout: {
          ...responsiveLayout,
          componentLayouts: [
            {
              ...responsiveLayout.componentLayouts[0],
              width: { type: 'percentage', value: 80 },
            },
          ],
          itemLayouts: [
            {
              ...responsiveLayout.itemLayouts[0],
              justifySelf: 'center',
            },
          ],
        },
      });
    });
  }
};
